<template>
  <div class="blog-page blog-page-categories-show">
    <div v-if="category">
      <div class="blog-page--hero">
        <h1 class="blog-page--page-title uppercase">
          {{ category.name }}
        </h1>
        <!-- <h3 class="blog-page--page-subtitle" v-if="category.children">
          {{ category.children.edges.length }} {{ $t('categories') }}
        </h3> -->
      </div>
      <!-- <div v-if="category.children.edges.length">
        <h2 class="text-center">
          {{ $t('Subcategories') }}
        </h2>
        <div class="blog-page--category-entries">
          <div class="container">
            <div class="row row--entries">
              <div class="col col-md-6 col-lg-4" v-for="(subcategory) in category.children.edges" :key="subcategory.id">
                <div class="blog-page--preview-small">
                  <div class="blog-page--preview-small-inner">
                    <h3 class="blog-page--preview-title">
                      <router-link :to="localizedRoute(`/categories/${category.slug}?id=${subcategory.node.id}`)">
                        {{ subcategory.node.name }}
                      </router-link>
                    </h3>
                    <router-link :to="localizedRoute(`/categories/${category.slug}?id=${subcategory.node.id}`)" class="blog-page--preview-link">
                      {{ $t('View') }} {{ subcategory.node.name }}&nbsp;<span class="slide-right">&rtrif;</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div v-if="category.posts.nodes && category.posts.nodes.length > 0">
        <h2 class="text-center">
          {{ $t('Posts') }}
        </h2>
        <div class="blog-page--entries">
          <div class="container">
            <div class="row row--entries">
              <div class="col col-md-6 col-lg-4" v-for="(post) in category.posts.nodes" :key="post.id">
                <div class="blog-page--preview-small">
                  <router-link :to="localizedRoute(`/porady/${post.slug}`)" class="blog-page--preview-small-bg" :style="getPostImage(post)">
                    &nbsp;
                  </router-link>
                  <div class="blog-page--preview-small-inner">
                    <h4 class="blog-page--preview-date">
                      {{ post.date | prettyDate }}
                    </h4>
                    <h3 class="blog-page--preview-title">
                      <router-link :to="localizedRoute(`/porady/${post.slug}`)">
                        {{ post.title }}
                      </router-link>
                    </h3>
                    <p class="blog-page--preview-excerpt" v-html="sanitizeHtml(post.excerpt, { allowedTags: [], allowedAttributes: {}})" />
                    <router-link :to="localizedRoute(`/porady/${post.slug}`)" class="blog-page--preview-link">
                      {{ $t('Learn More') }}&nbsp;<span class="slide-right">&rtrif;</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="m0 pb-600 text-center">
          {{ $t('There are no posts.') }}
        </h3>
      </div>
    </div>
    <div v-else>
      <h3 class="text-center">
        {{ $t('Category not found.') }}
      </h3>
    </div>
  </div>
</template>

<script>
import Show from 'src/modules/vsf-wordpress/components/categories/Show.js'

export default {
  mixins: [Show],
  name: 'CategoriesShow'
}
</script>

<style lang="scss" scoped>
@import 'src/modules/vsf-wordpress/components/blog/scss/blog';

.blog-page-categories-show {
  background: #f9f9f9;
  .text-center {
    text-align: center;
  }
  .blog-page--hero {
    background: #000;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 30px * 1.5;
  }
  .blog-page--page-title {
    display: block;
    text-align: center;
    color: $white;
    margin: 0 auto;
    max-width: 650px;
  }
  .blog-page--page-subtitle {
    display: block;
    text-align: center;
    color: $white;
    font-size: 16px;
    margin: 20px auto 10px;
    font-weight: 400;
  }
  .blog-page--category-entries {
    .blog-page--preview-small {
      margin: 0;
    }
    .blog-page--preview-title + .blog-page--subcategory-list {
      margin-top: -15px;
    }
  }
  .blog-page--subcategory-list {
    padding: 20px;
    background: #f3f3f3;
    margin: 30px 0 0;
    h5 {
      margin: 0 0 20px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 5px 0;
        a {
          color: lighten( $nearly-black, 30 );
          margin: 0;
        }
      }
    }
  }
  .blog-page--preview-link {
    &--and-more {
      color: #a2a2a2;
      margin: 0;
      font-size: 13px;
    }
  }
  .blog-page-subcategories-count {
    margin: -12px 0 6px;
    font-family: $PTSans;
    font-size: 12px;
    letter-spacing: 1.25px;
  }
}
.pb-600 {
  padding-bottom: 600px;
}
</style>
