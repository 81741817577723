import { mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'

export default {
  serverPrefetch () {
    return this.fetchCategory()
  },
  computed: {
    ...mapGetters({
      category: 'wordpress/currentCategory'
    }),
    featuredPost () {
      if (this.category.posts.nodes && this.category.posts.nodes.length > 0) {
        return this.category.posts.nodes.slice(0, 1)[0]
      } else {
        return null
      }
    }
  },
  watch: {
    '$route.path': function () {
      this.fetchCategory()
    }
  },
  mounted () {
    this.fetchCategory()
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.length > 0 ? text.substring(0, length) + suffix : ''
    },

    prettyDate: function (date) {
      let newDate = new Date(date)
      let dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return newDate.toLocaleDateString('pl-PL', dateFormat)
    }
  },
  methods: {
    fetchCategory () {
      if (this.$route.query.id != null) {
        return this.$store.dispatch('wordpress/loadCategoryById', { id: this.$route.query.id })
      } else {
        return this.$store.dispatch('wordpress/loadCategoryBySlug', { slug: this.$route.params.slug })
      }
    },
    getPostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.sourceUrl != null) {
        return {
          'backgroundImage': `url(${post.featuredImage.node.sourceUrl})`
        }
      } else {
        return {
          'backgroundColor': `#ccc`
        }
      }
    },
    sanitizeHtml: sanitizeHtml
  }
}
