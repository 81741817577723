import { Wordpress } from '../types/Wordpress';
import { GetterTree } from 'vuex';

export const getters: GetterTree<Wordpress, any> = {
  posts (state) {
    return state.posts
  },
  currentPageInfo (state) {
    return state.currentPageInfo
  },
  currentCategoryPageInfo (state) {
    return state.currentCategoryPageInfo
  },
  currentEdges (state) {
    return state.currentEdges
  },
  currentCategoryEdges (state) {
    return state.currentCategoryEdges
  },
  categories (state) {
    return state.categories
  },
  currentPost (state) {
    return state.currentPost
  },
  currentCategory (state) {
    return state.currentCategory
  },
  categoriesPreview (state) {
    return state.categoriesPreview
  }
}
