import { Wordpress } from '../types/Wordpress'
import { ActionTree } from 'vuex';
import * as types from './mutation-types'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import SearchAdapter from '../lib/searchAdapter'

const searchAdapter = new SearchAdapter()

// it's a good practice for all actions to return Promises with effect of their execution
export const actions: ActionTree<Wordpress, any> = {
  // if you want to use cache in your module you can load cached data like this
  async loadPost ({ commit }, { slug }) {
    // let query = 'query post ($id: ID!) { post(id: $id) { title date uri link content(format: RAW) } }'
    let query = 'query GET_POST($slug: String!) { postBy(slug: $slug) { title date modified tags {nodes {name} } uri link author { node { name } } categories { nodes { id name slug } } featuredImage { node { sourceUrl } } content } }'
    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { slug }
    }

    let result = await searchAdapter.search(Request)
    commit(types.SET_CURRENTPOST, result.data.postBy)
  },
  async loadPosts ({ commit, state }, { first }) {
    let query = 'query GET_POSTS($first: Int) { posts(first: $first) { pageInfo { hasNextPage hasPreviousPage } edges { cursor } nodes { id title date slug excerpt categories { nodes { name } } tags { nodes { name } } featuredImage { node { thumbnail: sourceUrl(size: THUMBNAIL) medium: sourceUrl(size: MEDIUM) large: sourceUrl(size: LARGE)  } } } } }'
    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { first }
    }
    let result = await searchAdapter.search(Request)
    let posts = result.data.posts.nodes
    let currentPageInfo = result.data.posts.pageInfo
    let currentEdges = result.data.posts.edges
    commit(types.SET_POSTS, { posts, currentPageInfo, currentEdges })
  },
  async loadPagePosts ({ commit }, { first, cursor }) {
    let query = 'query GET_POSTS($first: Int, $cursor: String) { posts(first: $first, after: $cursor) { pageInfo { hasNextPage hasPreviousPage } edges { cursor } nodes { id title date slug excerpt featuredImage { node { thumbnail: sourceUrl(size: THUMBNAIL) medium: sourceUrl(size: MEDIUM) large: sourceUrl(size: LARGE)  } } } } }'
    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { first, cursor }
    }
    let result = await searchAdapter.search(Request)
    let posts = result.data.posts.nodes
    let currentPageInfo = result.data.posts.pageInfo
    let currentEdges = result.data.posts.edges
    commit(types.ADD_POSTS, { posts, currentPageInfo, currentEdges })
  },
  async loadCategories ({ commit, state }, { first }) {
    let query = 'query GET_CATEGORIES($first: Int) { categories(first: $first) { nodes { id name slug count children { edges { node { id name slug } } } } } }'

    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { first }
    }

    let result = await searchAdapter.search(Request)

    let categories = result.data.categories.nodes
    commit(types.SET_CATEGORIES, categories)
  },
  async loadCategoriesPreview ({ commit }) {
    let query = 'query GET_CATEGORIES($first: Int) { categories(first: $first) { nodes { id name slug count children { edges { node { id name slug } } } posts(first:4) { nodes { id title date slug excerpt featuredImage { node { medium: sourceUrl(size: MEDIUM) } } } } } } }'

    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: {}
    }
    let result = await searchAdapter.search(Request)
    commit(types.SET_CATEGORIES_PREVIEW, result.data.categories.nodes)
  },
  async loadCategoryBySlug ({ commit }, { slug, first }) {
    let query = 'query GET_CATEGORY($first: Int) { categories(where: {slug: "' + slug + '"}) { nodes { id name slug count children { edges { node { id name slug } } } posts(first: $first) { pageInfo { hasNextPage hasPreviousPage } edges { cursor } nodes { id title date slug excerpt featuredImage { node { sourceUrl } } } } } } }'
    const storeView = currentStoreView()
    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { first }
    }
    let result = await searchAdapter.search(Request)
    let category = result.data.categories.nodes[0]
    let currentPageInfo = result.data.categories.nodes[0].posts.pageInfo
    let currentEdges = result.data.categories.nodes[0].posts.edges
    commit(types.SET_CURRENTCATEGORY, { category, currentPageInfo, currentEdges })
  },
  async loadCategoryBySlugPosts ({ commit }, { slug, first, cursor }) {
    let query = 'query GET_CATEGORY($first: Int, $cursor: String) { categories(where: {slug: "' + slug + '"}) { nodes { id name slug count children { edges { node { id name slug } } } posts(first: $first, after: $cursor) { pageInfo { hasNextPage hasPreviousPage } edges { cursor } nodes { id title date slug excerpt featuredImage { node { sourceUrl } } } } } } }'

    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { first, cursor }
    }
    let result = await searchAdapter.search(Request)
    let categoryPosts = result.data.categories.nodes[0].posts.nodes
    let currentPageInfo = result.data.categories.nodes[0].posts.pageInfo
    let currentEdges = result.data.categories.nodes[0].posts.edges
    commit(types.ADD_CATEGORYPOSTS, {categoryPosts, currentPageInfo, currentEdges})
  },
  async loadCategoryById ({ commit }, { id }) {
    let query = 'query category ($id: ID!) { category(id: $id) { id name slug count children { edges { node { id name slug } } } posts { nodes { id title date slug excerpt featuredImage { node { sourceUrl medium: sourceUrl(size: MEDIUM) } } } } } }'

    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { id }
    }

    let result = await searchAdapter.search(Request)

    commit(types.SET_CURRENTCATEGORY, { category: result.data.category })
  }
}
