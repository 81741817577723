import { MutationTree } from 'vuex'
import * as types from './mutation-types'

export const mutations: MutationTree<any> = {
  [types.SET_POSTS] (state, { posts, currentPageInfo, currentEdges }) {
    state.posts = posts
    state.currentPageInfo = currentPageInfo
    state.currentEdges = currentEdges
  },
  [types.ADD_POSTS] (state, { posts, currentPageInfo, currentEdges }) {
    state.currentEdges = currentEdges
    state.currentPageInfo = currentPageInfo
    state.posts = [...state.posts, ...posts]
  },
  [types.SET_CATEGORIES] (state, payload) {
    state.categories = payload
  },
  [types.SET_CURRENTPOST] (state, payload) {
    state.currentPost = payload
  },
  [types.SET_CURRENTCATEGORY] (state, { category, currentPageInfo, currentEdges }) {
    state.currentCategory = category
    state.currentCategoryEdges = currentEdges
    state.currentCategoryPageInfo = currentPageInfo
  },
  [types.ADD_CATEGORYPOSTS] (state, { categoryPosts, currentPageInfo, currentEdges }) {
    state.currentCategoryEdges = currentEdges
    state.currentCategoryPageInfo = currentPageInfo
    state.currentCategory.posts.nodes = [...state.currentCategory.posts.nodes, ...categoryPosts]
  },
  [types.SET_CATEGORIES_PREVIEW] (state, payload) {
    state.categoriesPreview = payload
  }
}
